<template>
  <b-card-code>
    <b-row>
      <b-col>
        <b-alert
          v-if="validateErrorFlag"
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            <span>{{ validateErrorMsg }} </span>
            <b-button
              class="ml-2"
              variant="outline-danger"
              size="sm"
              @click="validateErrorTable = !validateErrorTable"
            >
              <span v-if="validateErrorTable">Cerrar</span>
              <span v-else>Ver Errores</span>

            </b-button>
          </h4>
          <div
            v-if="validateErrorTable"
            class="alert-body mb-3"
          >
            <vue-good-table
              :columns="validateErrorColumns"
              :is-loading="loading"
              :rows="validateErrorData"
              :rtl="direction"
              :fixed-header="true"
              max-height="500px"
              style-class="vgt-table vgt-error-table"
            >
              <div
                slot="emptystate"
                class="text-center"
              >
                No se encontraron datos
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

            </vue-good-table>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          :disabled="loading"
          @click="download(rows)"
        >
          Exportar a Excel
        </b-button>
      </b-col>
      <b-col sm="6">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <b-form-input
              v-model="searchTerm"
              placeholder="Buscar"
              type="text"
              class="d-inline-block"
              @keyup.enter="search(rows, searchTerm)"
            />
          </b-form-group>
          <b-form-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :disabled="loading || searching"
              class="ml-1"
              @click="search(rows, searchTerm)"
            >
              <span v-if="!searching">Buscar</span>
              <span v-if="searching">Buscanado...</span>
            </b-button>
          </b-form-group>
        </div>
      </b-col>

    </b-row>
    <b-row>
      <b-col>
        <p>Mostrando: {{ rowsFiltered.length }} de {{ rows.length }}</p>
      </b-col>
    </b-row>


    <!-- table -->
    <vue-good-table
      :columns="columns"
      :is-loading="loading"
      :rows="rowsFiltered"
      :rtl="direction"
      :fixed-header="true"
      max-height="500px"
    >
      <div
        slot="emptystate"
        class="text-center"
      >
        No se encontraron datos
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status.status'">
          <b-badge :variant="statusVariant(props.row.status.status)">
            {{ props.row.status.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-button-group>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="upload(props.row.unidad, props.row.periodo)"
            >
              Cargar
            </b-button>

            <b-button
              v-if="props.row.status.idStatus == 'D'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="validate(props.row.unidad, props.row.periodo )"
            >
              Validar
            </b-button>
            <b-button
              v-if="props.row.status.idStatus == 'V'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="update(props.row.unidad)"
            >
              Aprobar
            </b-button>
          </b-button-group>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrando 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','25', '50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} registros </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card-code>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol,
  BButton,
  BButtonGroup, BAlert
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { downloadExcel } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import environment from '@/environment'

export default {
  name: 'AuxiliaryApproval',
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BButtonGroup,
    BCardCode,
    BAlert
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: true,
      searching: false,
      pageLength: 100,
      dir: false,
      columns: [
        {
          label: 'Periodo',
          field: 'periodo',
        },
        {
          label: 'Id Centro',
          field: 'idCentro',
        },
        {
          label: 'Unidad',
          field: 'unidad',
        },
        {
          label: 'Descripción',
          field: 'descripcion',
        },
        {
          label: 'Estado',
          field: 'status.status',
        },
        {
          label: 'Creado',
          field: 'creado',
          formatFn: this.date,
        },
        {
          label: 'Aprobado',
          field: 'aprobado',
          formatFn: this.date,
        },
        {
          label: 'Aprobado Por:',
          field: 'usuario',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      validateErrorColumns: [
        {
          label: 'Cod. Centro Origen',
          field: 'CODIGO_CENTRO_ORIGEN',
        },
        {
          label: 'Cta. Origen',
          field: 'CODIGO_CUENTA_ORIGEN',
        },
        {
          label: 'Cod. Centro Destino',
          field: 'CODIGO_CENTRO_DESTINO',
        },
        {
          label: 'Error',
          field: 'ERROR',
        },
      ],
      rows: [],
      rowsFiltered: [],
      statuses: [
        { idStatus: 'P', status: 'Pendiente' },
        { idStatus: 'C', status: 'Cerrado' },
        { idStatus: 'D', status: 'Cargado' },
        { idStatus: 'V', status: 'Validado' },
      ],
      searchTerm: '',
      validateErrorData: '',
      validateErrorMsg: '',
      validateErrorTable: false,
      validateErrorFlag : false
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Preactivo: 'light-preactivoCda',
        Inactivo: 'light-secondary',
        Activo: 'light-primary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      this.loading = true
      try {
        const user = JSON.parse(localStorage.getItem('userData'))
        const resp = await this.$http.get(`${environment.uri}/workflows/approvals/user/${user.email}`)

        if (resp && resp.data) {
          this.rows = resp.data.map(item => {
            const status = this.statuses.find(s => s.idStatus === item.status) || { idStatus: 'X', status: 'Sin Estado' }
            return {
              ...item,
              status,
            }
          })
          this.rowsFiltered = this.rows.slice(0, 100)
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    async update(unidad) {
      this.loading = true
      try {
        const user = JSON.parse(localStorage.getItem('userData'))
        await this.$http.put(`${environment.uri}/workflows/approval/id/${user.idCentro}`, {
          unidad,
          usuario: user.email,
        })
        await this.load()
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    async validate(auxCode, period) {
      this.loading = true
      try {
        const valiResp = await this.$http.post(`${environment.uri}/auxiliaries/validate`, {
          auxCode: auxCode,
          period: period,
        })
        await this.load()
          if(valiResp.data.vError){
            this.validateErrorMsg = valiResp.data.message
            this.validateErrorData = valiResp.data.records
            this.validateErrorFlag = true
          }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    async upload(unit, period) {
      this.loading = true
      try {
        await this.$http.get(`${environment.uri}/workflows/import/unit/${unit}/period/${period}`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cargado',
            icon: 'BellIcon',
          },
        })

        await this.load()

      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error en la carga',
            icon: 'BellIcon',
          },
        })
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    currency(value) {
      return `$${this.$options.filters.currency(value)}`
    },
    date(value) {
      return this.$options.filters.date(value)
    },
    download(rows) {
      const items = rows.map(item => ({
        periodo: (item.periodo || ''),
        idCentro: (item.idCentro || ''),
        unidad: (item.unidad || ''),
        descripcion: (item.descripcion || ''),
        status: (item.status.status || ''),
        creado: (item.creado || 0),
        aprobado: (item.aprobado || 0),
      }))
      downloadExcel(items, [
        'Periodo',
        'Id Centro',
        'Unidad',
        'Descripción',
        'Estado',
        'Creado',
        'Aprobado',
      ], 'report.csv')
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
.vgt-error-table{
  background-color: transparent !important;
}
</style>
